import { AppData } from '../../../../viewer.app';
import {
  completeFetchPost,
  fetchPost,
  preFetchPost,
} from '../../../common/actions/fetch-post';
import type { IWixCodeApi } from '../../../common/controller/wix-code-api';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  AppStore,
  FlowAPI,
  NormalizedPost,
  RouteResolverFn,
} from '../../../common/types';
import { setReadingSessionId } from '../../actions/reading-session-id-actions';
import { ROUTE_404 } from '../../constants/routes';
import { postPageRouterPostHandler } from './post-page-router-post-handler';
import { setPostIDInSlots } from './slot-handler';

interface CreatePostPageRouterParams {
  store: AppStore;
  wixCodeApi: IWixCodeApi;
  appData: AppData;
  flowAPI: FlowAPI;
  slotAPIFactory: Record<string, any>;
}

export const createPostPageRouter =
  ({
    store,
    wixCodeApi,
    appData,
    flowAPI,
    slotAPIFactory,
  }: CreatePostPageRouterParams): RouteResolverFn =>
  async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const postSlug = resolvePostSlug(params);

    if (!postSlug) {
      throw new Error('post slug is not available');
    }

    if (preFetch) {
      return store.dispatch(preFetchPost(postSlug));
    }

    const readingSessionId = `${parseInt(
      `${Math.random() * 10000000}`,
      10,
    )}-${Date.now()}`;
    store.dispatch(setReadingSessionId(readingSessionId));

    return (
      preFetchResult
        ? store.dispatch(completeFetchPost(postSlug, preFetchResult))
        : store.dispatch(fetchPost(postSlug))
    )
      .then((post: NormalizedPost) => {
        setPostIDInSlots(post.id, slotAPIFactory);
        return postPageRouterPostHandler({
          post,
          store,
          appData,
          wixCodeApi,
          flowAPI,
          redirect,
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }

        if (error.status === 404) {
          return redirect(ROUTE_404);
        }

        throw error;
      });
  };
